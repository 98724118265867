.flex {
    flex-direction: column;
    align-items: self-start;
    padding: 20px;
    column-gap: 12px;
}

div.external {
    width: 24%;
    max-height: 260px;
    background: white;
    border: 1px solid #F1F1F1;
}

div.external:hover {
    border: 1px solid black;
}

div.external:hover h3 {
    color: black;
}

div.external:hover .jobTitle {
    color: white;
    background-color: black;
}

div.external:hover .slider {
    width: 35px;
    transition: width 0.2s ease-in;
}

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0D0D0D;
    margin: 0px;
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #919191;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #767676;
    margin-bottom: 25px;
}

img.profileImage {
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
}

img.emoji {
    width: 24px;
    height: 24px;
}

div.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    height: 24px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 24px;
}

div.nameTag {
    margin-top: 12px;
    height: 60px;
}

div.jobTitle {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 4px 14px;
    margin-right: 5px;
    gap: 8px;
    border: 1px solid #919191;
    border-radius: 10px;
    max-width: 75%;
    max-height: 80px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.slider {
    height: 2px;
    background-color: black;
    width: 0;
    justify-self: start;
    transition: width 0.3s ease-in;
    display: inline-block;
}
