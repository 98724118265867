.profileCardContainer {
    max-height: 835px;
    overflow-y: scroll;
}

.profileCardFlex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: none;
    border-bottom: none;
    margin-top: 25px;
}