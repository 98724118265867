.surveyReplyPanel{
    height: 482px;
    width: 100%;
    border-bottom: 1px solid #F1F1F1;
    border-top: 1px solid #F1F1F1;
    border-left: 2px solid #F1F1F1;
    overflow: scroll;
}

.surveyReplyPanel span{
    color: #919191;
}

.surveyReplyPanel div {
    text-align: left;
    margin-left: 60px;
}

.question {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 64px 100px 30px;
}

.answerGrid {
    border-top: 2px solid #F1F1F1;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 25% 75%;
    margin-right: 100px;
    margin-bottom: 25px;
    min-height: 20px; 
    overflow: hidden;
}

.allReplies {
    margin-top: 10px;
    font-weight: bold;
    text-decoration-line: underline;
}