.toolbarSectionDivider {
    padding: 10px;
    border-left: 2px solid #F1F1F1;
    border-bottom: 2px solid #F1F1F1;
    align-self: baseline;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toolbarSelectedFilterList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toolbarTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0D0D0D;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 70%;
    padding: 8px 0
}

button {
    position: relative;
    padding: 0.375rem 0.88rem;
    color: #333;
    background-color: #FFFFFF;
    font-weight: 400;
    border: 1px solid transparent;
    font-size: 0.85rem;
    line-height: 1.5;
    cursor: pointer;
}

.individualSelectedFilters {
    float: left;
    width: auto;
    padding: 4px;
}

.alignButtonContents {
    text-align: center;
    border-radius: 0;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
}

.removeFilterButton {
    background-color: #0D0D0D;
    color: #FFFFFF;
    line-height: 0;
    padding: revert;
    align-self: center;
    white-space: nowrap;
}

.filterButtonContainer {
    flex: auto;
    display: inline-block;
    text-align: center;
    position: relative;
    width: 50%;
}