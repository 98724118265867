.menuItems{
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 44px;

    margin-top: 50px;
    margin-bottom: 125px;
}

.menuItems a {
    color: #ADADAD;
}

.menuItems hr {
    width: 95%;
    border-color: #ADADAD;
    margin: 0 0 0 40px;
}

.menuItems a:hover{
    color: black;
}

.menuItems li{
    color: white;
    list-style-type: none;
}

.menuItems li:hover *{
    border-color: black;
}

.menuItems .listItemLink {
  display: block;
  height: 60px;
  padding: 25px 0 5px 60px;
}

.menuItems .firstListItemLink {
  padding-top: 0px;
}