.searchBar{
    border: none;
    height: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.searchInput {
    border: none;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100px;
    font-size: 16px;
}

.searchIcon{
    margin-top: 10px;
    margin-bottom: 10px;
}