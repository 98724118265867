.menuNavbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 5em;
}

.menu-nav-items {
    display: flex;
    justify-self: center;
    text-align: center;
}

.menu-nav-items div {
    width: 368px;
    height: 188px;
    position: absolute;
    top: 0;
    right: 0;
    background: #0D0D0D;
    line-height: 194px;
}

#xIcon {
    color: white;
}

.instilLogo {
    display: flex;
    align-self: center;
    margin-left: 30px;
    margin-top: 50px;
    max-width: 170px;
    max-height: 30px;
}

