.chart {
    max-width: 953px;
    max-height: 500px;
    border: 2px solid #F1F1F1;
    border-left: none;
    border-bottom: none;
    padding: 20px;
    width: 100%;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 82px;
    width: 852px;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #F1F1F1;
}

.responsiveContainer {
  padding: 0;
}