h1 {
    font-weight: 400;
    font-size: 20px;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.tab-item {
    margin: 0 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.title-section {
    margin-bottom: 10px;
    padding: 20px 10px 0 10px;
}

.selected {
    border-bottom: 1px solid #000000;
}

.unselected {
    color: #ADADAD;
    font-weight: lighter;
}