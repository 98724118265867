.dashboardGrid {
    border: 2px solid #F1F1F1;
    border-bottom: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 459px 500px
}

.chartDashboardPanel {
    grid-column: 1;
    grid-row: 1;
}
.toolbarDashboardPanel{
    grid-column: 2;
    grid-row: 1 / span 2;
    overflow: hidden;
    border-bottom: 2px solid #F1F1F1;
    margin-bottom: 10px;
}

.interactionReportDashboardPanel{
    grid-column: 1;
    grid-row: 2;
    margin-top: 38px;
}