a {
    color: #000000;
    text-decoration: none;
}

.navbar {
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr;
    justify-content: space-between;
    margin: 0;
    height: 180px;
}

.nav-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -8px;
}

.nav-items a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid #f1f1f1;
}

.nav-items a:not(.current):hover {
    background-color: #f1f1f1;
}

.current {
    background-color: black;
}

.current * {
    color: white;
}

.logo {
    align-self: center;
    justify-self: start;
    margin-left: 30px;
    max-width: 170px;
    max-height: 30px;
}

.menuNavItem {
    display: flex;
    align-items: center;
    justify-content: right;
    color: #242424;
    padding-right: 160px;
    margin-top: -8px;
    border-left: 2px solid #F1F1F1;
}

.menuNavItem GiHamburgerMenu {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
}