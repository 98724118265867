img.arrowStyle {
    vertical-align: middle;
    width: 0.84rem;
    height: 15px;
}

.dropdownFilters {
    display: flex;
}

.unselectedFilter {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #ADADAD;
}

.selectedFilter {
  font-size: 13px;
}

.dropdown {
    position: absolute;
    max-height: 210px;
    top: 48px;
    left: -111px;
    border: 1px solid rgb(197, 197, 197);
    background: #fff;
    padding: 0;
    color: #333;
    text-align: left;
    overflow-y: auto;
    z-index: 1000
}
.dropdown > p {
    margin: 0;
    padding: 0.375rem 0.88rem;
    border-bottom: 1px solid rgb(235, 235, 235);
    min-width: 150px;
}
.dropdown > p:last-child {
    border-bottom: 0 none;
}

.checkboxIcon {
    vertical-align: middle;
    width: 10px;
    height:10px;
    padding: inherit;
}

p:hover {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

p:hover .hoverCheckbox {
    display: revert;
    position: absolute;
}

p:hover .checkedFilter {
    display: revert;
}

.hoverCheckbox {
    display: none;
}

p .checkedFilter {
    display: inherit;
    justify-content: left;
}

p .defaultCheckBox {
    justify-content: left;
}

p .notSelected {
    display: none;
}

p .hasBeenSelected {
    display: none;
}

.showArrow {
    display: inline;
}
.hideArrow {
    display: none;
}