.menu-button {
    width: 300px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #0D0D0D;
    border: 1px solid #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 12px;
    margin-left: 20px;
}

.reportGrid {
    border: 2px solid #F1F1F1;
    display: grid;
    grid-template-columns: 40% 60%;
}

.reportGrid > div {
    text-align: center;
    height: 1041px;
}

.item1 {
    grid-column: 1;
    grid-row: 1;
}
.item2{
    grid-column: 2;
    grid-row: 1;
}

.reportBox{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid #F1F1F1;
    border-bottom: none;
}

.surveyResponses{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 2px solid #F1F1F1;
    border-bottom: 1px solid #F1F1F1;
    border-top: none;
    border-right: none;
    width: 100%;
    height: 72px;
}

.surveyResponseText{
    margin-left: 60px;
}

