span {
    margin-right: 5px;
}

.activity {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 400px;
    padding: 10px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.hiFive {
    background-color: #164CFF;
    margin: 5px 10px;
}

.loFive {
    background-color: #29CC6A;
    margin: 5px 10px;
}