.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    padding: 96px 32px 48px;
    gap: 32px;
    color: #0D0D0D;
}

.flex-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginLogo {
    max-width: 170px;
    max-height: 30px;
}

.heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    width: 360px;
    height: 38px;
}

.description {
    width: 360px;
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: -20px;
}

.login-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 12px;
    width: 360px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #919191;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.login-button:hover {
    background-color: #f1f1f1;
}