.count {
    margin-bottom: 0;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #F1F1F1;
}

.primary-blue {
    color: #164CFF;
}

.primary-green {
    color: #29CC6A;
}

.type {
    margin-top: 5px;
}