.userMoodSummaryPanel{
    height: 300px;
    width: 100%;
    border-bottom: 2px solid #F1F1F1;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.uniformPanel{
    height: 184px;
    width: 100%;
    border-bottom: 2px solid #F1F1F1;
    display: flex;
    flex-direction: row;
    overflow: scroll;
}

.individualsName{
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #29CC6A;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 168px;
    overflow: hidden;
}

.userProfilePicture {
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    margin-top: 70px;
    position: relative;
    width: 150px;
    height: 150px;
    left: 56px;
}

.roleBox{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    font-size: 12px;
    color: #FFFFFF;
    width: 150px;
    height: 28px;
    background: #C8C8C8;
    border: 0.5px solid #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 300px;
    margin-bottom: 19px;
    overflow: hidden;
    pointer-events: none;
}

p{
    all: unset;
    all: revert;
}

.team{
    width: 105px;
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
    display: flex;
    vertical-align: top;
    color: #29CC6A;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 20px;
    overflow: hidden;
}

.flexLine{
    display: flex;
    align-items: center;
}

.last6Weeks{
    width: 132px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 180px;
    margin-top: 75px;
    overflow: hidden;
}

.userAverage{
    width: 133px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 75px;
    margin-left: 200px;
    overflow: hidden;
}

.moodDip{
    width: 230px;
    height: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 75px;
    margin-left: 160px;
    overflow: hidden;
}

.averageOverWeeks{
    width: 315px;
    height: 36px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 75px;
    margin-left: 120px;
    overflow: hidden;
}

.moodImage{
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    margin-top: 50px;
    margin-left: 100px;
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
}

.sixWeekMoodImageSummary{
    margin-top: 50px;
}

.sixWeekMoodImage{
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    width: 75px;
    height: 75px;
    position: relative;
    overflow: hidden;
}

.sixWeekMoodImage + .sixWeekMoodImage {
    margin-left: -48px;
}

.moodImageSpread{
    border-radius: 50%;
    margin-top: 50px;
    left: 50px;
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
}

.averageMoodImageContainer{
  margin-top: 50px;
  width: 100%;
}

.averageMoodImage{
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
}

.userInfo{
  margin-top: -90px;
}