.box {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
}

.buttonUnselected {
    left: 300px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    align-items: center;
    color: #ADADAD;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.buttonSelected {
    left: 300px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 12px;
    align-items: center;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-bottom: 1px solid #000000
}

.reportCardHeading {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: start;
    max-width: 90%;
}

.\interactionReportPanel {
    display: grid;
    height: 448px;
    grid-template-columns: 25% 25% 25% 25%;
    border: 2px solid #F1F1F1;
    border-top: 1px solid #F1F1F1;
    border-left: none;
    border-right: none;
    grid-template-rows: 100%;
    text-align: center;
}

.interactionReportPanelItem {
    border: 1px solid #F1F1F1;
}

.interactionReportPanelUserContainer {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    margin-left: 10%;
    align-items: center;
    margin-top: 10px;
}

.interactionReportPanelUserContainer > div {
    margin-top: 20px;
    max-width: 10%;
}

.interactionReportPanelUserContainer > div > img {
    justify-self: center;
}

.monthText{
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 13px;
    color: #919191;
}

.itemHeader{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    margin-left: 10%;
}

.userProfile {
    z-index: 1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.unknownProfilePicture {
    filter: grayscale(1) brightness(1.5);
}